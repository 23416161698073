<template>
  <div class="autoScheduler" :class="{'loading' : loading}">
    <vue-scroll :ops="ops">
      <h2 class="autoScheduler__title">Automated Scheduler</h2>
      <div class="autoScheduler_wrapper">
        <div class="autoScheduler__left">
          <div class="selection_mode_wrapper">
            <label
              class="slider_wraper_left"
              :class="[{ slider_checked: selectionMode === 'singleDate' }]"
              @click="onResetDate"
            >
              <input
                type="radio"
                value="singleDate"
                v-model="selectionMode"
                class="checkbox"
              />
                Single Date
            </label>
            <label
              class="slider_wraper_right"
              :class="[{ slider_checked: selectionMode === 'range' }]"
              @click="onResetDate"
            >
              <input
                type="radio"
                value="range"
                v-model="selectionMode"
                class="checkbox"
              />
                Date Range
            </label>
          </div>
          <br/>
          <div class="date_wrapper">
            <div class="date_container">
              <div class="list_wrapper">
                <button
                  v-for="item of providerCalendar"
                  :key="item.id"
                  @click="() => {
                    setActiveJet(item);
                    onResetDate();
                  }"
                  class="list_item"
                  :class="[
                    { list_item__active: activeJet && activeJet.id === item.id },
                  ]"
                >
                  {{ item.name }}
                </button>
              </div>
              <div class="picker_container">
                <div class="pickerControl">
                  <button class="clear_date"
                    @click="onResetDate"
                  >
                    Reset Dates
                    <span class="icon"><SvgIcon name="close"/></span>
                  </button>
                </div>
                <v-date-picker v-if="isSingleDateMode"
                  :attributes="attrsCalendar"
                  class="picker_date"
                  v-model="selectedDate"
                  is-expanded
                  locale="en"
                  color="green"
                  :available-dates="getAvailableDatesForSingleDateMode"
                  @dayclick="onDayClick"
                />
                <v-date-picker v-else-if="isRangeMode"
                  :attributes="attrsCalendar"
                  v-model="rangeDate"
                  is-range
                  class="picker_date"
                  is-expanded
                  locale="en"
                  color="green"
                  :available-dates="getAvailableDatesForRangeMode"
                  @dayclick="onDayClick"
                />
              </div>
            </div>
            <div class="date_desc">
              <div
                class="date_desc_item"
                v-for="item of dateDesc"
                :key="item.text"
              >
                <span class="color" :style="{ background: item.color }" />
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="isSelectedDate" class="autoScheduler__right">
          <p class="chosen_var">
            {{ getHeader }}
          </p>
          <p v-if="isCharterAvailability" class="autoScheduler__right_subtitle">
            CHARTER AVAILABILITY priced by the hour
          </p>
          <p v-else class="autoScheduler__right_subtitle">
            CHARTER FLIGHT (EMPTY LEG) priced by the route
          </p>
          <p class="autoScheduler__right_subtitle__large">pricing</p>
          <div v-if="isCharterFlightAvailability" class="input_wrapper">
            <p class="inputs_label">Static Route Price</p>
            <input
              class="field"
              type="number"
              v-model="staticRoutePrice"
              placeholder="Currency amount"
              autocomplete="off"
            />
          </div>
          <div v-if="isCharterAvailability" class="input_wrapper">
            <p class="inputs_label">minimum acceptable cost</p>
            <input
              class="field"
              type="number"
              v-model="minimumAcceptablePrice"
              placeholder="Currency amount"
              autocomplete="off"
            />
          </div>
          <div v-if="isCharterAvailability" class="pricing_wrapper">
            <label
              class="label_wrapper"
              :class="[
                { label_checked: pricingMode === pricingModeOptions.default },
              ]"
            >
              <input
                type="radio"
                value="default"
                v-model="pricingMode"
                class="checkbox"
              />
              <span class="checkbox_view" />
              Default Hourly Rate
            </label>
            <label
              class="label_wrapper"
              :class="[
                {
                  label_checked:
                    pricingMode === pricingModeOptions.hourly_price,
                },
              ]"
            >
              <input
                type="radio"
                value="hourly_price"
                v-model="pricingMode"
                class="checkbox"
              />
              <span class="checkbox_view" />
              New Hourly Rate
            </label>
            <input
              class="field"
              type="number"
              v-model="hourPrice"
              placeholder="Enter $"
              autocomplete="off"
              :disabled="pricingMode === pricingModeOptions.default"
            />
          </div>
          <div v-if="isCharterFlightAvailability" class="pricing_wrapper">
            <label
              class="label_wrapper"
              :class="[
                { label_checked: pricingMode === pricingModeOptions.default },
              ]"
            >
              <input
                type="radio"
                value="default"
                v-model="pricingMode"
                class="checkbox"
              />
              <span class="checkbox_view" />
              Default Hourly Rate
            </label>
            <label
              class="label_wrapper"
              :class="[
                {
                  label_checked:
                    pricingMode === pricingModeOptions.hourly_price,
                },
              ]"
            >
              <input
                type="radio"
                value="hourly_price"
                v-model="pricingMode"
                class="checkbox"
              />
              <span class="checkbox_view" />
              New Hourly Rate
            </label>
            <label
              class="label_wrapper"
              :class="[
                { label_checked: pricingMode === pricingModeOptions.na },
              ]"
            >
              <input
                type="radio"
                value="na"
                v-model="pricingMode"
                class="checkbox"
              />
              <span class="checkbox_view" />
              N/A
            </label>
            <input
              class="field"
              type="number"
              v-model="charterFlightHourPrice"
              placeholder="Enter $"
              autocomplete="off"
              :disabled="pricingMode !== pricingModeOptions.hourly_price"
            />
          </div>
          <div class="autoScheduler__right_twoItems">
            <div class="airports_wrapper">
              <p class="inputs_label">
                POTENTIAL DEPARTURE AIRPORTS AND<br/> LOCATIONS
              </p>
              <div class="airports_line"
                  v-for="(departure, index) in departures"
                  :key="departure.listId"
              >
                <AirportsInput
                  :defaultValue="departure.name"
                  @setValue="(value) => setAirport(value, departure.listId, 'departure', index)"
                />
                <div v-if="departures.length === index + 1 && departures.length < 3" class="button_plus_wrapper">
                  <button v-if="isCharterAvailability"
                    @click="addAirportToList('departure')"
                    class="plus_button"
                  >
                    <SvgIcon name="plus4"/>
                  </button>
                </div>
                <div v-else class="button_plus_wrapper">
                  <button
                    @click="removeAirport(departure.listId, 'departure')"
                    class="plus_button del_button"
                  >
                    <SvgIcon name="plus4"/>
                  </button>
                </div>
            </div>
              <!-- <label class="form__label form__label--checkbox"
                :class="[{'checked' : defaultAirports.includes('defaultDeparture')}]"
              >
                <input
                  class="form__checkbox"
                  type="checkbox"
                  v-model="defaultAirports"
                  value="defaultDeparture"
                  @change="(event) => {
                    setDefaultAirport(event.target.checked, 'departure')
                  }"
                />
                <i class="form__checkbox-icon"></i>
                <span class="form__checkbox-box">Default departure location</span>
              </label> -->
            </div>
            <div class="input_wrapper">
              <p class="inputs_label">Departure Re-Routing<br/> Radius</p>
              <input
                class="field"
                type="number"
                v-model="departureRadius"
                placeholder="Enter nautical miles"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="autoScheduler__right_twoItems">
            <div class="airports_wrapper">
              <p class="inputs_label">
                POTENTIAL LANDING AIRPORTS AND<br/> LOCATIONS
              </p>
              <div class="airports_line"
                v-for="(landing, index) in landings"
                :key="landing.listId"
              >
                <AirportsInput
                  :defaultValue="landing.name"
                  @setValue="(value) => setAirport(value, landing.listId, 'landing')"
                />
                <div v-if="landings.length === index + 1 && landings.length < 3" class="button_plus_wrapper">
                  <button v-if="isCharterAvailability"
                    @click="addAirportToList('landing')"
                    class="plus_button"
                  >
                    <SvgIcon name="plus4"/>
                  </button>
                </div>
                <div v-else class="button_plus_wrapper">
                  <button
                    @click="removeAirport(landing.listId, 'landing')"
                    class="plus_button del_button"
                  >
                    <SvgIcon name="plus4"/>
                  </button>
                </div>
              </div>
              <!-- <label class="form__label form__label--checkbox"
                :class="[{'checked' : defaultAirports.includes('defaultLanding')}]"
              >
                <input
                  class="form__checkbox"
                  type="checkbox"
                  v-model="defaultAirports"
                  value="defaultLanding"
                  @change="(event) => {
                    setDefaultAirport(event.target.checked, 'landing')
                  }"
                />
                <i class="form__checkbox-icon"></i>
                <span class="form__checkbox-box">Default landing location</span>
              </label> -->
            </div>
            <div class="input_wrapper">
              <p class="inputs_label">Landing Re-Routing<br/> Radius</p>
              <input
                class="field"
                type="number"
                v-model="landingRadius"
                placeholder="Enter nautical miles"
                autocomplete="off"
              />
            </div>
          </div>
          <button v-if="isCharterAvailability" class="buttonBlackOutline" @click="addAircraftAvailability">
            <span class="text">UPDATE AVAILABILITY</span>
          </button>
          <button v-else class="buttonBlackOutline" @click="editEmptyLeg">
            <span class="text">UPDATE flight</span>
          </button>
          <button v-if="isCharterAvailability" class="buttonBlackOutline" @click="removeAircraftAvailability">
            <span class="text">REMOVE AVAILABILITY</span>
          </button>
          <button v-if="isCharterFlightAvailability" class="buttonBlackOutline" @click="removeEmptyLegs">
            <span class="text">REMOVE flight</span>
          </button>
        </div>
      </div>
    </vue-scroll>
    <AlternativePopup
      :name="'editAvailabilityOrEmptyLeg'"
      :title="'Found charter availability on the same day as the empty legs'"
      :text="'Do you want to edit availability or empty legs?'"
      :firstAlternative="'Availability'"
      :secondAlternative="'Empty Legs'"
      @firstAlternativeClick="setCurrentAircraftAvailabilityFields"
      @secondAlternativeClick="setCurentEmptyLegFields"
    />
    <DropdownListPopup
      :name="'selectEmptyLeg'"
      :title="'Choose which empty leg to edit:'"
      :list="currentEmptyLegList"
      @selectClick="selectEmptyLegClick"
    />
    <InfoPopup />
  </div>
</template>

<script>
  import AlternativePopup from '@/components/common/modals/AlternativePopup'
  import DropdownListPopup from '@/components/common/modals/DropdownListPopup'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import AirportsInput from './AirportsInput/AirportsInput';
  import moment from 'moment';
  import InfoPopup from '@/components/common/modals/InfoPopup';
  import './AutomatedScheduler.styl'
  export default {
    name: 'AutomatedScheduler',
    data: () => ({
      loading: false,
      selectionMode: 'singleDate',
      selectedDate: '',
      departures: [{
        name: '',
        id: Math.random(),
        listId: Math.random(),
      }],
      landings: [{
        name: '',
        id: Math.random(),
        listId: Math.random(),
      }],
      departureRadius: '',
      landingRadius: '',
      rangeDate: {
        start: '',
        end: '',
      },
      currentAircraftAvailability: null,
      currentEmptyLeg: null,
      currentEmptyLegList: [],
      pricingModeOptions: {
        default: 'default',
        hourly_price: 'hourly_price',
        na: 'na',
      },
      defaultAirports: [],
      pricingMode: 'default',
      hourPrice: '',
      charterFlightHourPrice: '',
      staticRoutePrice: '',
      dateFormat: 'MM/DD/YYYY',
      activeJet: {
        away_from_home_base: [],
        is_zero_pax: [],
        unavailable: [],
        charter_availability: [],
        charter_flight_availability: [],
        part_available_dates: [],
      },
      alreadyEst: [],
      dateDesc: [
        { text: 'Charter (Outright) Availability', color: '#2E62B0' },
        { text: 'Charter Flight Availability (Empty Legs)', color: '#FDFFA7' },
        { text: 'Unavailable', color: 'black' },
        { text: 'Away from home base', color: 'teal' },
        { text: 'Part of date available', color: 'pink' },
        { text: 'Zero pax', color: 'orange' },
      ],
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
      changePartOfAvailability: false,
      emptyLegsList: [],
    }),
    computed: {
      attrsCalendar () {
        return [
          {
            key: 'Away from home base',
            highlight: {
              fillMode: 'solid',
              contentClass: 'italic',
              style: { background: 'teal', borderRadius: '7px' },
            },
            dot: {
              style: { background: 'teal', borderColor: 'black', borderWidth: '0.5px', borderStyle: 'solid'},
            },
            dates: this.activeJet ? this.activeJet.away_from_home_base.map(p => p.date) : [],
          },
          {
            key: 'Zero pax',
            highlight: {
              fillMode: 'solid',
              contentClass: 'italic',
              style: { background: 'orange', borderRadius: '7px' },
            },
            dot: {
              style: { background: 'orange'},
            },
            dates: this.activeJet ? this.activeJet.is_zero_pax.map(p => p.date) : [],
          },
          {
            key: 'Charter (Outright) Availability',
            highlight: {
              fillMode: 'solid',
              contentClass: 'italic',
              style: { background: '#2E62B0', borderRadius: '7px' },
            },
            dates: this.activeJet ? this.activeJet.charter_availability.map(c => c.displayed_period) : [],
          },
          {
            key: 'Unavailable',
            highlight: {
              fillMode: 'solid',
              contentClass: 'italic',
              style: { background: 'black', borderRadius: '7px' },
            },
            dates: this.activeJet ? this.activeJet.unavailable.map(u => u.date) : [],
          },
          {
            key: 'Part of date available',
            highlight: {
              fillMode: 'solid',
              contentClass: 'italic',
              style: { background: 'pink', borderRadius: '7px' },
            },
            content: 'black',
            dates: this.activeJet ? this.activeJet.part_available_dates.map(p => p.displayed_date) : [],
          },
          {
            key: 'Charter Flight Availability (Empty Legs)',
            highlight: {
              fillMode: 'solid',
              style: { background: '#FDFFA7', borderRadius: '7px' },
            },
            content: 'black',
            dates: this.activeJet ? this.activeJet.charter_flight_availability.map(c => c.date) : [],
          },
        ]
      },
      getAvailableDatesForSingleDateMode () {
        const charterAvailability = this.activeJet ? this.activeJet.charter_availability.map(c => c.date) : [];
        const charterFlightAvailability = this.activeJet ? this.activeJet.charter_flight_availability.map(c => c.date) : [];
        return [...charterAvailability, ...charterFlightAvailability];
      },
      getAvailableDatesForRangeMode () {
        const charterAvailability = this.activeJet ? this.activeJet.charter_availability.map(c => c.date) : [];
        return charterAvailability;
      },
      isSingleDateMode () {
        return this.selectionMode === 'singleDate';
      },
      isRangeMode () {
        return this.selectionMode === 'range';
      },
      isSelectedDate () {
        return (!!this.currentAircraftAvailability && !this.currentEmptyLeg) || (!!this.currentEmptyLeg && !this.currentAircraftAvailability);
      },
      isCharterAvailability () {
        return !!this.currentAircraftAvailability && !this.currentEmptyLeg;
      },
      isCharterFlightAvailability () {
        return !!this.currentEmptyLeg && !this.currentAircraftAvailability;
      },
      providerCalendar () {
        return this.$store.state.providerCalendar.list;
      },
      getHeader () {
        let header = this.activeJet.name;
        if (this.selectionMode === 'singleDate') {
          header += ' ' + this.selectedDate.toLocaleDateString('en-US');
        } else {
          const startDate = Date.parse(this.rangeDate.start);
          const endDate = Date.parse(this.rangeDate.end);
          header += ' ' + (new Date(startDate)).toLocaleDateString('en-US');
          if (startDate !== endDate) {
            header += ' - ' + (new Date(endDate)).toLocaleDateString('en-US');
          }
        }
        return header;
      },
    },
    watch: {
    pricingMode: function (newPricingMode) {
      if (newPricingMode === this.pricingModeOptions.default) {
        this.hourPrice = this.activeJet.price_per_hour;
      }
    },
  },
    async created () {
      this.$store.state.providerCalendar.list = [];
      await this.$store.dispatch('getCurrentProviderCalendar');
      this.setActiveJet(this.providerCalendar[0]);
    },
    methods: {
      moment,
      setAirport (value, id, type, index) {
        if (type === 'departure') {
         this.departures = this.departures.map((departure) => {
           if (departure.listId === id) {
              return ({
                id: value.id,
                listId: `${value.id}${index}`,
                name: value.name,
              })
           } else return departure;
         })
       } else {
         this.landings = this.landings.map((landing) => {
           if (landing.listId === id) {
             return ({
               id: value.id,
               listId: `${value.id}${index}`,
               name: value.name,
             })
           } else return landing;
         })
       }
      },
      removeAirport (id, type) {
        if (type === 'departure') {
          this.departures = this.departures.filter(({listId}) => listId !== id)
        } else {
          this.landings = this.landings.filter(({listId}) => listId !== id)
        }
      },
      addAirportToList (type) {
        if (type === 'departure') {
          this.departures = [...this.departures, { name: '', id: Math.random(), listId: Math.random() }]
        } else {
          this.landings = [...this.landings, { name: '', id: Math.random(), listId: Math.random() }]
        }
      },
      // setDefaultAirport (isChecked, type) {
      //   if (isChecked) {
      //     if (type === 'departure') {
      //       this.departures[0] = {
      //         name: this.activeJet.home_base.name,
      //         id: this.activeJet.home_base.id,
      //         listId: this.departures[0].listId
      //       };

      //       const allAicraftEvents = [...this.activeJet.unavailable, ...this.activeJet.charter_flight_availability];
      //       const nearestEvent = this.getClosestDateInArray(allAicraftEvents, 'end', this.rangeDate.start);
      //       if (nearestEvent) {
      //         this.departures[0] = {
      //           name: nearestEvent.landing.name,
      //           id: nearestEvent.landing.id,
      //           listId: this.departures[0].listId
      //         };
      //       }
      //     } else {
      //       this.landings[0] = {
      //         name: this.activeJet.home_base.name,
      //         id: this.activeJet.home_base.id,
      //         listId: this.landings[0].listId
      //       };

      //       const allAicraftEvents = [...this.activeJet.unavailable, ...this.activeJet.charter_flight_availability];
      //       const nearestEvent = this.getClosestDateInArray(allAicraftEvents, 'start', this.rangeDate.end);
      //       if (nearestEvent) {
      //         this.landings[0] = {
      //           name: nearestEvent.departure.name,
      //           id: nearestEvent.departure.id,
      //           listId: this.landings[0].listId
      //         };
      //       }
      //     }
      //   } else {
      //     if (type === 'departure') {
      //       this.departures.shift();
      //       if (!this.departures.length) {
      //         this.departures = [{
      //           name: '',
      //           id: Math.random(),
      //           listId: Math.random()
      //         }]
      //       }
      //     } else {
      //       this.landings.shift();
      //       if (!this.landings.length) {
      //         this.landings = [{
      //           name: '',
      //           id: Math.random(),
      //           listId: Math.random()
      //         }]
      //       }
      //     }
      //   }
      // },
      onResetDate () {
        this.selectedDate = '';
        this.rangeDate = {start: '', end: ''};
        this.currentAircraftAvailability = null;
        this.currentEmptyLeg = null;
        this.currentEmptyLegList = [];
        this.clearAllInputs();
      },
      clearAllInputs () {
        this.clearLocationInputs();
        this.departureRadius = '';
        this.landingRadius = '';
        this.pricingMode = 'default';
        this.hourPrice = this.activeJet.price_per_hour;
        this.charterFlightHourPrice = this.activeJet.charter_flight_price_per_hour;
        this.minimumAcceptablePrice = this.activeJet.minimum_acceptable_price;
        this.staticRoutePrice = '';
        this.defaultAirports = [];
      },
      async addAircraftAvailability () {
        this.loading = true;
        let dateStart = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), 0, 0, 0);
        let endStart = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), 23, 59, 59);
        if (this.selectionMode === 'range') {
          dateStart = new Date(this.rangeDate.start.getFullYear(), this.rangeDate.start.getMonth(), this.rangeDate.start.getDate(), 0, 0, 0);
          endStart = new Date(this.rangeDate.end.getFullYear(), this.rangeDate.end.getMonth(), this.rangeDate.end.getDate(), 23, 59, 59);
        }
        const data = {
          aircraftAvailabilityId: this.currentAircraftAvailability.id,
          aircraftId: this.activeJet.id,
          departureLocations: this.departures.map(d => ({locationTreeId: d.id})),
          departureReroutingRadius: this.departureRadius,
          arrivalLocations: this.landings.map(l => ({locationTreeId: l.id})),
          arrivalReroutingRadius: this.landingRadius,
          pricePerHour: this.hourPrice,
          minimumAcceptablePrice: this.minimumAcceptablePrice,
          sellCharterSeat: false,
          periods: [{
            from: this.getCurrentTimeZoneDate(dateStart),
            to: this.getCurrentTimeZoneDate(endStart),
          }],
        }
        const estOffset = ((new Date()).getTimezoneOffset() - 240) * 60 * 1000;
        const newAvailabilities = await this.$store.dispatch('changeAvailabilityInSchedulerUi', data);
        newAvailabilities.charter_availability = newAvailabilities.charter_availability.map(a => ({
          id: a.id,
          departures: a.departures,
          landings: a.landings,
          date: {
            start: new Date(Date.parse(a.date.start) + estOffset),
            end: new Date(Date.parse(a.date.end) + estOffset),
          },
          displayed_period: {
            start: new Date(Date.parse(a.displayed_period.start) + estOffset),
            end: new Date(Date.parse(a.displayed_period.end) + estOffset),
          },
          departure_radius: a.departure_radius,
          landing_radius: a.landing_radius,
          price_per_hour: a.price_per_hour,
          minimum_acceptable_price: a.minimum_acceptable_price,
        }));
        newAvailabilities.part_available_dates = newAvailabilities.part_available_dates.map(a => ({
          id: a.id,
          departures: a.departures,
          landings: a.landings,
          date: {
            start: new Date(Date.parse(a.date.start) + estOffset),
            end: new Date(Date.parse(a.date.end) + estOffset),
          },
          displayed_date: new Date(Date.parse(a.displayed_date) + estOffset),
          departure_radius: a.departure_radius,
          landing_radius: a.landing_radius,
          price_per_hour: a.price_per_hour,
          minimum_acceptable_price: a.minimum_acceptable_price,
        }));
        const currentPartAvailabilities = this.activeJet.part_available_dates.filter(e => e.id === this.currentAircraftAvailability.id);
        for (let i = 0; i < currentPartAvailabilities.length; i++) {
            const partAvailableIndex = this.activeJet.charter_availability.indexOf(currentPartAvailabilities[i]);
            this.activeJet.part_available_dates.splice(partAvailableIndex, 1);
        }
        const currentAvailabilityindex = this.activeJet.charter_availability.indexOf(this.currentAircraftAvailability);
        this.activeJet.charter_availability.splice(currentAvailabilityindex, 1);
        this.activeJet.charter_availability = [...this.activeJet.charter_availability, ...newAvailabilities.charter_availability];
        this.activeJet.part_available_dates = [...this.activeJet.part_available_dates, ...newAvailabilities.part_available_dates];
        this.onResetDate();
        this.loading = false;
        this.$modal.show('InfoPopup', {
          title: 'Charter availability was successfully updated.',
          text: '',
        });
      },
      async editEmptyLeg () {
        this.loading = true;
        const data = {
          departureAirportId: this.departures[0].id,
          arrivalAirportId: this.landings[0].id,
          exclusiveCost: this.staticRoutePrice,
          departureReroutingRadius: this.departureRadius,
          arrivalReroutingRadius: this.landingRadius,
          emptyLegId: this.currentEmptyLeg.id,
        };
        const result = await this.$store.dispatch('editEmptyLeg', data);
        this.loading = false;
        if (result.isSuccessfull) {
          const currentEmptyLegIndex = this.activeJet.charter_flight_availability.indexOf(this.currentEmptyLeg);
          this.activeJet.charter_flight_availability[currentEmptyLegIndex].static_route_price = this.staticRoutePrice;
          this.activeJet.charter_flight_availability[currentEmptyLegIndex].departure_radius = this.departureRadius;
          this.activeJet.charter_flight_availability[currentEmptyLegIndex].landing_radius = this.landingRadius;
          this.activeJet.charter_flight_availability[currentEmptyLegIndex].departure.name = this.departures[0].name;
          this.activeJet.charter_flight_availability[currentEmptyLegIndex].departure.id = this.departures[0].id;
          this.activeJet.charter_flight_availability[currentEmptyLegIndex].landing.name = this.landings[0].name;
          this.activeJet.charter_flight_availability[currentEmptyLegIndex].landing.id = this.landings[0].id;
          this.onResetDate();
          this.$modal.show('InfoPopup', {
            title: 'Charter flight was successfully updated.',
            text: '',
          });
        } else {
          console.log(result.message);
          this.$modal.show('InfoPopup', {
            title: 'Sorry. Error has occured.',
            text: '',
          });
        }
      },
      async removeAircraftAvailability () {
        const data = {
          availabilityId: this.currentAircraftAvailability.id,
        };
        await this.$store.dispatch('removeAircraftAvailability', data);
        this.$router.go();
      },
      async removeEmptyLegs () {
        const data = {
          emptyLegId: this.currentEmptyLeg.id,
        };
        await this.$store.dispatch('removeEmptyLegs', data);
        this.$router.go();
      },
      onDayClick (data) {
        this.clearAllInputs();
        this.currentAircraftAvailability = null;
        this.currentEmptyLeg = null;
        this.currentEmptyLegList = [];
        this.selectedDate = data.date;
        this.selectedDate.setHours(0, 0, 0, 0);
        const selectedDateInMilliseconds = this.selectedDate.getTime();
        if (this.selectionMode === 'singleDate') {
          this.currentEmptyLegList = this.activeJet.charter_flight_availability.filter(e =>
          Date.parse(new Date(e.date.getFullYear(), e.date.getMonth(), e.date.getDate())) === selectedDateInMilliseconds);
          this.currentAircraftAvailability = this.activeJet.charter_availability.filter(e =>
            Date.parse(new Date(e.date.start.getFullYear(), e.date.start.getMonth(), e.date.start.getDate())) <= selectedDateInMilliseconds &&
            Date.parse(new Date(e.date.end.getFullYear(), e.date.end.getMonth(), e.date.end.getDate())) >= selectedDateInMilliseconds)[0];
          if (this.currentEmptyLegList.length === 1) {
            this.currentEmptyLeg = this.currentEmptyLegList[0];
          }
        } else {
          if (this.rangeDate && this.rangeDate.start && this.rangeDate.end) {
            const rangeEndDate = Date.parse(new Date(this.rangeDate.end.getFullYear(), this.rangeDate.end.getMonth(), this.rangeDate.end.getDate()));
            const rangeStartDate = Date.parse(new Date(this.rangeDate.start.getFullYear(), this.rangeDate.start.getMonth(), this.rangeDate.start.getDate()));
            if (rangeEndDate === selectedDateInMilliseconds || rangeStartDate === selectedDateInMilliseconds) {
             this.currentAircraftAvailability = this.activeJet.charter_availability.filter(e =>
             Date.parse(new Date(e.date.start.getFullYear(), e.date.start.getMonth(), e.date.start.getDate())) <= rangeStartDate &&
             Date.parse(new Date(e.date.end.getFullYear(), e.date.end.getMonth(), e.date.end.getDate())) >= rangeEndDate)[0];
            }
          }
        }
        if (this.currentEmptyLegList.length && !this.currentAircraftAvailability) {
          this.setCurentEmptyLegFields();
        } else if (!this.currentEmptyLegList.length && this.currentAircraftAvailability) {
          this.setCurrentAircraftAvailabilityFields();
        } else if (this.currentEmptyLegList.length && this.currentAircraftAvailability) {
          this.$modal.show('editAvailabilityOrEmptyLeg');
        } else {
          this.onResetDate();
        }
      },
      setCurentEmptyLegFields () {
        if (!this.currentEmptyLeg) {
          this.$modal.show('selectEmptyLeg');
        } else {
          this.clearAllInputs();
          this.rangeDate = {start: '', end: ''};
          this.currentAircraftAvailability = null;
          this.currentEmptyLegList = [];
          this.staticRoutePrice = this.currentEmptyLeg.static_route_price;
          this.departureRadius = this.currentEmptyLeg.departure_radius;
          this.landingRadius = this.currentEmptyLeg.landing_radius;
          this.departures[0] = {
            name: this.currentEmptyLeg.departure.name,
            id: this.currentEmptyLeg.departure.id,
            listId: this.departures[0].listId,
          };
          this.landings[0] = {
            name: this.currentEmptyLeg.landing.name,
            id: this.currentEmptyLeg.landing.id,
            listId: this.landings[0].listId,
          };
        }
      },
      setCurrentAircraftAvailabilityFields () {
        this.clearAllInputs();
        this.currentEmptyLeg = null;
        this.currentEmptyLegList = [];
        this.hourPrice = this.currentAircraftAvailability.price_per_hour;
        if (this.activeJet.price_per_hour === this.currentAircraftAvailability.price_per_hour) {
          this.pricingMode = this.pricingModeOptions.default;
        } else {
          this.pricingMode = this.pricingModeOptions.hourly_price;
        }
        this.minimumAcceptablePrice = this.currentAircraftAvailability.minimum_acceptable_price;
        this.departureRadius = this.currentAircraftAvailability.departure_radius;
        this.landingRadius = this.currentAircraftAvailability.landing_radius;
        this.departures[0] = {
          name: this.currentAircraftAvailability.departures[0].name,
          id: this.currentAircraftAvailability.departures[0].id,
          listId: this.departures[0].listId,
        };
        for (let i = 1; i < this.currentAircraftAvailability.departures.length; i++) {
            this.departures = [...this.departures, {
              name: this.currentAircraftAvailability.departures[i].name,
              id: this.currentAircraftAvailability.departures[i].id,
              listId: Math.random(),
            }]
        }
        this.landings[0] = {
          name: this.currentAircraftAvailability.landings[0].name,
          id: this.currentAircraftAvailability.landings[0].id,
          listId: this.landings[0].listId,
        };
        for (let i = 1; i < this.currentAircraftAvailability.landings.length; i++) {
            this.landings = [...this.landings, {
              name: this.currentAircraftAvailability.landings[i].name,
              id: this.currentAircraftAvailability.landings[i].id,
              listId: Math.random(),
            }]
        }
      },
      selectEmptyLegClick (emptyLegId) {
        this.currentEmptyLeg = this.currentEmptyLegList.filter(el => el.id === emptyLegId)[0];
        this.setCurentEmptyLegFields();
      },
      getClosestDateInArray (array, dateParam, dateToCompare) {
        let minDiff = null;
        let mostAccurateDate = null;
        if (dateParam === 'end') {
          array.map((item) => {
            const diff = moment(dateToCompare).diff(item.date['end'], 'minutes', true);
            if (diff > 0 && (!minDiff || diff < minDiff)) {
              minDiff = diff;
              mostAccurateDate = item
            }
          });
        } else if (dateParam === 'start') {
          array.map((item) => {
            const diff = moment(item.date['start']).diff(dateToCompare, 'minutes', true);
            if (diff > 0 && (!minDiff || diff < minDiff)) {
              minDiff = diff;
              mostAccurateDate = item
            }
          });
        }
        return mostAccurateDate;
      },
      getCurrentTimeZoneDate (date) {
        const offset = ((new Date()).getTimezoneOffset() - 240) * 60 * 1000;
        return new Date(date.getTime() - offset);
      },
      setEstTimeZone () {
        if (this.alreadyEst.includes(this.activeJet.name)) {
          return;
        }

        const estOffset = ((new Date()).getTimezoneOffset() - 240) * 60 * 1000;
        this.activeJet.away_from_home_base = this.activeJet.away_from_home_base.map(a => ({
          date: {
            start: new Date(Date.parse(a.date.start) + estOffset),
            end: new Date(Date.parse(a.date.end) + estOffset),
          },
        }));
        this.activeJet.is_zero_pax = this.activeJet.is_zero_pax.map(a => ({
          date: new Date(Date.parse(a.date) + estOffset),
        }));
        this.activeJet.unavailable = this.activeJet.unavailable.map(a => ({
          date: {
            start: new Date(Date.parse(a.date.start) + estOffset),
            end: new Date(Date.parse(a.date.end) + estOffset),
          },
        }));
        this.activeJet.charter_availability = this.activeJet.charter_availability.map(a => ({
          id: a.id,
          departures: a.departures,
          landings: a.landings,
          date: {
            start: new Date(Date.parse(a.date.start) + estOffset),
            end: new Date(Date.parse(a.date.end) + estOffset),
          },
          displayed_period: {
            start: new Date(Date.parse(a.displayed_period.start) + estOffset),
            end: new Date(Date.parse(a.displayed_period.end) + estOffset),
          },
          departure_radius: a.departure_radius,
          landing_radius: a.landing_radius,
          price_per_hour: a.price_per_hour,
          minimum_acceptable_price: a.minimum_acceptable_price,
        }));
        this.activeJet.part_available_dates = this.activeJet.part_available_dates.map(a => ({
          id: a.id,
          departures: a.departures,
          landings: a.landings,
          date: {
            start: new Date(Date.parse(a.date.start) + estOffset),
            end: new Date(Date.parse(a.date.end) + estOffset),
          },
          displayed_date: new Date(Date.parse(a.displayed_date) + estOffset),
          departure_radius: a.departure_radius,
          landing_radius: a.landing_radius,
          price_per_hour: a.price_per_hour,
          minimum_acceptable_price: a.minimum_acceptable_price,
        }));
        this.activeJet.charter_flight_availability = this.activeJet.charter_flight_availability.map(a => ({
          id: a.id,
          static_route_price: a.static_route_price,
          departure_radius: a.departure_radius,
          landing_radius: a.landing_radius,
          departure: a.departure,
          landing: a.landing,
          date: new Date(Date.parse(a.date) + estOffset),
        }));
        this.alreadyEst.push(this.activeJet.name);
      },
      setActiveJet (item) {
        this.activeJet = item;
        this.setEstTimeZone();
        this.hourPrice = this.activeJet ? this.activeJet.price_per_hour : 0;
        this.charterFlightHourPrice = this.activeJet ? this.activeJet.charter_flight_price_per_hour : 0;
        this.minimumAcceptablePrice = this.activeJet ? this.activeJet.minimum_acceptable_price : 0;
      },
      clearLocationInputs () {
        this.departures = [{
          name: '',
          id: Math.random(),
          listId: Math.random(),
        }];
        this.landings = [{
          name: '',
          id: Math.random(),
          listId: Math.random(),
        }];
      },
    },
    components: {
      AlternativePopup,
      DropdownListPopup,
      SvgIcon,
      AirportsInput,
      InfoPopup,
    },
  }
</script>