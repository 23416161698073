<template>
  <modal :name="name" transition="pop-out" @opened="opened">
    <div class="thanksPopup modal">
      <div class="thanksPopup__decor"></div>
      <div class="thanksPopup__inner">
        <div class="thanksPopup__title">{{title}}</div>
        <vue-scroll :ops="ops">
          <div class="listItem" v-for="listItem of list" :key="listItem.id" @click="() => selectItem(listItem.id)">
            <p>{{listItem.date.toLocaleTimeString('en-US')}} ({{listItem.departure.code}} - {{listItem.landing.code}})</p>
          </div>
        </vue-scroll>
      </div>
      <button type="button" class="modal__close" @click="this.hide">
        <SvgIcon name="close-white"/>
      </button>
    </div>
  </modal>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import './DropdownListPopup.styl'

  export default {
    name: 'DropdownListPopup',
    props: ['title', 'text', 'name', 'list'],
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: '#bdbaba',
        },
      },
    }),
    methods: {
      show () {
        this.$modal.show(this.name)
      },
      hide () {
        this.$modal.hide(this.name)
      },
      selectItem (id) {
        this.hide()
        this.$emit('selectClick', id)
      },
      opened () {
        if (document.querySelector('html').classList.contains('d-mobile')) {
          const popup = document.querySelector('.vm--container')
          document.querySelector('body').appendChild(popup)
        }
      },
    },
    created () {
      this.$parent.$on('showModal', this.show)
    },
    components: {
      SvgIcon,
    },
  }
</script>
